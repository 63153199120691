@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPrice {
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    &-NotLoggedInPrice {
        display: block;
        margin: 24px 0;
        color: $color-secondary-50
    }

    &-Wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        padding: 26px;

        &-Left {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
        }
    }

    &-Price {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-Right {
        font-size: 20px;
        line-height: 28px;
        color: $black;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: $color-other-8;
            width: 43px;
            margin-right: 16px;
            display: block;
        }
    }

    &-Wrapper:first-child {
        background: $color-other-6;
        margin-bottom: 1px;
    }

    &-Wrapper:nth-child(2) {
        background: $color-other-7;
    }

    > span {
        margin-right: 12px;
    }

    &-HighPrice {
        order: 1;
        color: $color-neutral-50;

        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 9px;
            width: 100%;
            height: 1px;
            background: $color-neutral-50;
        }
    }

    .DiscountLabel {
        display: block;
    }

    &-PriceBadge {
        padding-right: 5px;
    }

    &_notPDP {
        display: block;
        margin: 0;
        font-size: 12px;

        .ProductPrice {
            &-Wrapper {
                padding: 0;
                background: none;
                height: auto;
                justify-content: start;
            }

            &-Left {
                width: 100px;
                line-height: 18px;
            }

            &-Right {
                font-size: 12px;
                color: $color-neutral-90;
                line-height: 18px;

                span {
                    order: 1;
                    font-size: 12px;
                    color: $color-neutral-90;

                    &::first-letter {
                        text-transform: lowercase;
                    }
                }
            }

            &-Price {
                > span {
                    padding-left: 8px;
                }
            }
        }
    }
}

@include mobile {
    .ProductPrice {
        &-Wrapper {
            padding: 20px 17px;
        }

        &-Left {
            max-width: 86px;
        }

        &_notPDP {
            font-size: 10px;

            .ProductPrice {
                &-Wrapper {
                    display: block;
                    margin-bottom: 6px;
                }

                &-Left {
                    width: 100%;
                }

                &-Right {
                    span {
                        font-size: 10px;
                    }
                }

                &-Price {
                    justify-content: start;
                }
            }
        }
    }
}

@include narrow-desktop {
    .ProductPrice {
        &_notPDP {
            .ProductPrice {
                &-Wrapper {
                    display: block;
                    margin-bottom: 6px;
                }

                &-Left {
                    width: 100%;
                }

                &-Price {
                    justify-content: start;
                }
            }
        }
    }
}

@include desktopAndAbove {
    .ProductPrice {
        flex-direction: row;

        &-Wrapper {
            max-width: 350px;
        }
    }
}
