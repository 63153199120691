@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutSuccessProduct {
    display: grid;
    grid-gap: 20px;
    align-items: flex-start;
    padding: 2rem 0rem;
    border-bottom: 1px solid $color-other-9;

    img {
        max-width: 100px;
    }


    &-Box {
        display: flex;
        flex-direction: column;
        text-align: center;

        &_qty {
            font-size: 16px;
        }
    }

    &-Box:nth-child(2), &-Box:nth-child(4) {
        text-align: initial;
    }

    &-Box:nth-child(4) {
        align-items: end;
    }

    &-Box:first-of-type {
        flex-direction: row;
    }

    &-SubBox {
        padding-left: 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            font-size: 16px;
        }
    }

    &-OriginalPrice {
        display: flex;
        flex-direction: row;
        min-width: fit-content;
        margin-bottom: 4px;

        span {
            display: block;
            width: 48px;
            font-size: 12px;
        }

        &_isCrossed {
            text-decoration: line-through;
            color: $color-neutral-50;
            font-size: 1.15rem;
        }

        &:last-child {
            color: $color-neutral-60;
            margin-bottom: 0;
        }
    }

    &-OriginalPriceTitle {
        color: $color-neutral-90 !important;
    }

    &-SpecialPrice {
        margin-bottom: 0;
    }

    &-DeliveryBox {
        display: flex;
        align-items: center;
        font-size: 14px;

        > span {
            margin-left: 8px;
            letter-spacing: 0.5px;
        }

        svg {
            top: -1px;
        }
    }
}

@include mobile {
    .CheckoutSuccessProduct {
        grid-template-columns: repeat(3, 1fr);

        &-MobileProductTitle {
            text-align: center;
            margin-bottom: 1rem;
        }

        &-Box:last-of-type {
            grid-area: 2 / 3 / 3 / 4;
        }


        &-Box:first-of-type {
            grid-area: 1 / 1 / 2 / 4;
            margin-bottom: 1rem;
        }

        &-Box:nth-child(2) {
            display: none;
        }

        &-Box:nth-child(3) {
            grid-area: 2 / 2 / 3 / 3;
            flex-direction: row;
            font-size: 12px;
            color: $color-neutral-60;
            align-self: end;
            line-height: 18px;

            p {
                color: $color-neutral-60;
                font-size: 12px;
                display: inline;
                text-align: left;
                margin-bottom: 0;
                margin-right: 5px;
                line-height: 18px;
            }
        }
    }
}

@include tabletAndAbove {
    .CheckoutSuccessProduct {
        grid-template-columns: 1fr 150px 30px 150px;
        padding: 32px 2rem;

        &-MobileProductTitle {
            display: none;
        }

        img {
            max-width: 120px;
        }

        &-SubBox {
            padding-left: 16px;
        }
    }
}

@include desktopAndAbove {
    .CheckoutSuccessProduct {
        grid-template-columns: 1fr 150px 150px 150px;

        &-SubBox {
            padding-left: 32px;
        }

        img {
            max-width: 200px;
        }
    }
}
