@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttachments {
    padding: 3rem 0 ;
    border-bottom: 1px solid $color-neutral-30;
    
    &-Files {
        padding: 1.5rem;
        display: flex;
        gap: 2rem;

        a {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
        }
    }
}
