@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductsPerPage {
    margin-left: 40px;
    gap: 10px;

    @include flex($align: center);

    .Field {
        margin-bottom: 0;

        select {
            height: 40px;
            padding-left: 16px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 13px;
        }
    }
}

@include mobileAndTablet {
    .ProductsPerPage {
        display: none;
    }
}
