@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductConfigurableAttributes {
    font-size: 12px;
    line-height: 20px;
    flex-direction: column;

    &-SwatchList,
    &-DropDownList {
        text-align: center;
        margin-block-start: 2px;
        margin-block-end: 12px;
    }

    &-Title {
        font-size: 12px;
        margin-block: 8px;
    }

    &-Left {
        &-Title {
            font-size: 12px;
            margin-block: 12px;
        }
    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .ProductAttributeValue {
            cursor: pointer;

            &_isNotAvailable {
                pointer-events: all;
                opacity: .4;

                >span {
                    background: linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%),
                        linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%);
                }
            }

            &-String {
                border: 1px solid $color-neutral-70;
                font-size: 12px;
                padding: 2px 10px;
                min-width: 36px;

                &:hover {
                    color: $color-neutral-90;
                    border: 1px solid $color-neutral-90;
                    font-weight: 400;
                }

                &_isSelected {
                    color: $color-neutral-90;
                    border: 3px solid $color-neutral-90;
                    font-weight: 400;
                    padding: 0 8px;

                    &:hover {
                        color: $color-neutral-90;
                        border: 3px solid $color-neutral-90;
                        padding: 0 8px;
                    }
                }
            }

            &-ColorSwatch {
                .Field_type_checkbox {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &-DropDownList {
        width: 100%;
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block: var(--option-margin-block);
        margin-inside: var(--option-margin-inline);
    }

    &-Expandable:first-of-type {
        border-block-start: none;
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: none;
    }
}
