@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttributes {
    font-size: 14px;
    line-height: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-other-9;

    .TypographyHeader {
        margin-top: 0;
    }

    &-AttributeBlock {
        break-inside: avoid;
        overflow: hidden;
        width: 100%;
        max-width: 910px;
        margin-left: auto;
        margin-right: auto;

        &:last-child {
            .ProductAttributes {
                &-AttributeLabel, &-ValueLabel {
                    &:last-of-type {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Group {
        margin: 0;
        padding-block: 32px 16px;
    }

    &-Attributes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: flex-start;
    }

    &-ValueLabel,
    &-AttributeLabel {
        border-bottom: 1px solid $color-other-9;
        flex: 0 0 50%;
        overflow: hidden;
        padding: 20px 24px;
        text-overflow: ellipsis;
        height: 100%;
        line-height: 20px;
    }

    &-AttributeLabel {
        text-align: right;
        color: $color-neutral-70;
        border-right: 1px solid $color-other-9;
    }

    &-Image {
        padding-block-end: 40%;
        mix-blend-mode: multiply;
        margin-inline-end: 120px;
        min-width: 360px;
        background: none;

        @include mobile {
            margin-inline-end: 140px;
            min-width: 420px;
            display: none;
        }
    }
}

@include mobileAndTablet {
    .ProductAttributes {
        &-ValueLabel,
        &-AttributeLabel {
            padding: 15px 10px;
        }
    }
}

@include desktopAndAbove {
    .ProductAttributes {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}
