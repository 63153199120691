@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompareItem {
    &-AddToCartBtnWrapper {
        width: 100%;
    }

    &-AddToCartBtn {
        height: 48px;
    }

    &-Details {
        margin-bottom: 32px;
    }

    .ProductCompareItem {
        &-Title {
            padding-right: 45px;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 8px;
            font-weight: 600;
            margin-top: 0;
        }

        &-Actions {
            .ProductWishlistButton {
                position: absolute;
                right: 0;
                margin-right: 0;
                width: auto;
            }
        }
    }
}
