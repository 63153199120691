@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAlert {
    margin-top: 20px;

    &-Header {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .CmsBlock-Wrapper {
        &, & p {
            font-size: 12px;
            color: $color-neutral-70;
            line-height: 18px;
        }
    }

    button {
        width: 100%;
    }
}

