@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCardBanner {
    list-style: none;
    padding: 0;
    margin-bottom: 24px;

    &::before {
        display: none;
    }
}
