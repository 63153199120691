@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryProductList {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
    }

    &-Page {
        &_layout {
            &_grid {
                display: grid;
                grid-auto-rows: max-content;
                grid-column-gap: 24px;
                grid-template-columns: 1fr 1fr;

                @include desktopAndAbove {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include mobile {
                    padding: 0;
                    border: 0;
                }

                @include desktop {
                    grid-column: 2;
                    margin-block-start: 12px;
                }

                // remove margin from top to other pages

                ~ .CategoryProductList-Page {
                    @include desktop {
                        margin-block-start: 0;
                    }
                }
            }

            &_list {
                display: block;
            }
        }

        // remove margin from top to other pages

        ~ .CategoryProductList-Page {
            @include desktop {
                margin-block-start: 0;
            }
        }
    }

    &-ProductsMissing {
        padding: 12px;
        text-align: center;

        @include mobile {
            padding: 14px;
        }
    }

    &-More {
        min-height: 36px;

        @include mobile {
            min-height: 42px;
        }
    }

    &-LoadButton {
        background-color: var(--category-product-list-load-button-background);
        cursor: pointer;
        font-size: 17px;
        margin-block-end: 12px;
        padding: 1em 0;
        text-align: center;

        @include mobile {
            margin-block-end: 14px;
        }
    }

    .Pagination {
        position: absolute;
        right: 0;
        top: -90px;
    }

    .ProductListPage {
        + .Pagination {
            position: relative;
            top: 0;
            margin-top: 20px;

            .Pagination-List {
                justify-content: end;
            }
        }
    }
}

@include mobileAndTablet {
    .CategoryProductList {
        .Pagination {
            display: none;
        }

        .ProductListPage {
            + .Pagination {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
    }
}
