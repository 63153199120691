@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-compare-column-gap: 12px;
    --product-compare-sidebar-width: 330px;
    --product-compare-item-gap: 12px;
    --product-compare-column-width-mobile: 300px;
    --product-compare-column-width: calc(270px + var(--product-compare-item-gap) * 2);
}

.ProductCompare {
    @include scrollbar;

    .ClearButton {
        align-items: flex-end;
        justify-content: center;
        padding: 0 var(--product-compare-column-gap) 32px 0;
        max-width: 300px;

        .Button {
            max-width: 242px;
            margin-inline-end: 0;
        }
    }

    &-Empty {
        margin-left: 0;
        text-align: center;
        margin-top: 6.5rem;
    }

    &-InfoText {
        margin-top: 2rem;
        font-size: 2rem;
    }

    &-BackButton {
        margin-top: 6rem;
        margin-bottom: 2rem;

        &:hover {
            color: var(--button-color);
        }
    }

    .ProductPrice {
        padding: 0;

        &-Wrapper {
            flex-wrap: wrap;
        }
    }
}

[dir="ltr"] .ProductCompare {
    &-Item {
        padding-right: var(--product-compare-column-gap);
    }
}

@include mobileAndTablet {
    .ProductCompare {
        &-Item,
        .ProductPrice,
        .ProductCompareAttributeRow-Value {
            width: var(--product-compare-column-width-mobile);
            padding: 0;
        }

        &-FirstColumn.ClearButton {
            width: 100%;
            padding: 32px 0;

            .Button {
                width: 100%;
            }
        }
    }
}
